body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
  'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.konvajs-content {
  /*background: seagreen;*/
}

body {
  min-height: 100vh;
  min-height: 100dvh;
  overflow: hidden;
}

html {
  background: url(../public/images/classic_bg.jpg) 50% no-repeat;
  background-size: cover;
}

.hints-layer {
  z-index: 120;
}

.backdrop {
  position: fixed;
  z-index: 100;
  inset: 0;
  background-color: rgba(0, 0, 0, .5);
  pointer-events: none;
  transition: opacity .25s ease-in-out;
  animation: showBackDrop .25s ease-in-out;
}

.backdrop.backdrop-hide {
  opacity: 0;
}

@keyframes showBackDrop {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}